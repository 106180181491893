<script setup lang="ts">
import type {SharedPage} from "~/interface";
import {useUserStore} from "~/stores/user";

const CONTENT_TYPE = 9
const {instance} = defineProps<{ instance: SharedPage }>()
const us = useUserStore()
const isVote = ref(instance.is_vote)
const voteCount = ref(instance.meta ? (instance.meta["vote_count"] || 0) : 0)
const vote = async () => {
  if (us.isLogged) {
    const {data: res} = await useAuthFetch<number>('/activity/votes/', {
      method: "POST",
      body: {
        target_content_type: CONTENT_TYPE,
        target_object_id: instance.id
      }
    })
    if (res.value) {
      isVote.value = voteCount.value < res.value
      voteCount.value = res.value
    }
  }
}

watch(() => instance.is_vote, () => {
  isVote.value = instance.is_vote
})
</script>

<template>
  <div class="flex gap-1 items-center cursor-pointer" @click="vote">
    <div :class="['w-5 h-5', isVote ? 'i-con-heart text-red-500' : 'i-con-heart-outline']"/>
    <div class="uppercase font-semibold text-xs">{{ voteCount}}</div>
  </div>
</template>

<style scoped>

</style>
