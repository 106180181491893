<template>
  <div class="relative z-0 overflow-hidden">
    <div class="absolute z-10 top-0 left-0">
      <div v-if="showVote" class="rounded-br-xl bg-gray-100 px-2 py-1 shadow">
        <div
          v-if="isAdding"
          class="flex gap-1 py-0.5 items-center cursor-pointer text-xs uppercase font-semibold"
          @click="emits('select')"
        >
          <div :class="['w-2 h-2 i-con-plus', added ? 'rotate-45' : '']"/>
          <span>{{added ? 'remove': 'add'}}</span>
        </div>
        <action-vote v-else :instance="value"/>
      </div>
    </div>
    <nuxt-link
      :to="to" :title="value.name"
      class="card group"
    >
      <div class="block pt-full relative bg-white">
        <div class="absolute inset-2">
          <img v-lazy-load 
            v-if="!isDraw"
            :data-src="src" :alt="value.name"
            class="duration-300 w-full h-full"
          />
          <canvas
            v-else
            :id="`canvas_${value.id}`"
            class="duration-300 w-full h-full"
            width="200"
            height="200"
          />
        </div>
      </div>
      <div class="card-head group-hover:translate-y-0">
        <div class="flex gap-2 items-center">
          <div class="flex-1 font-semibold hover:underline whitespace-nowrap truncate">
            {{ value.name || value.id_string || "Untitled" }}
          </div>
        </div>
      </div>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import type {SharedPage} from "~/interface";
import {useRuntimeConfig} from "nuxt/app";
import {drawThumbnail} from "~/helper/canvas";
import {computed} from "vue";

const {value, isDraw, isAdding, added, showVote} = defineProps<{
  value: SharedPage, isDraw?: boolean, isAdding?: boolean, added?: boolean, showVote?: boolean
}>()
const config = useRuntimeConfig()

const emits = defineEmits(['select'])

const src = computed(() => {
  return `${config.public.apiBase}/coloring/files/art-thumbnail/${value.id_string}.png`
})
const to = computed(() => {
  return isDraw ? `/editor?id=${value.id_string || value.id}` : `/art/${value.id_string}`
})

onMounted(() => {
  if (isDraw) {
    drawThumbnail(value)
  }
})
</script>
